import React, { useState } from "react";
import { Auth } from "aws-amplify";

import { useFormFields } from "../../libs/hooksLib";
import "./Login.css";

export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    try {
      await Auth.signIn(fields.email, fields.password);
      props.userHasAuthenticated(true);
      props.history.push("/");
    } catch (e) {
      alert(e.message);
    }
  }

  return (
    <div className="Login">
      <form onSubmit={handleSubmit} className="mui-form">
        <div className="mui-textfield">
            <input id="email" type="email" value={fields.email} onChange={handleFieldChange} />
            <label htmlFor="email">Email</label>
        </div>
        <div className="mui-textfield">
            <input id="password" type="password" value={fields.password} onChange={handleFieldChange} />
            <label htmlFor="password">Password</label>
        </div>
        <button type="submit" className="mui-btn mui-btn--raised mui-btn--primary" disabled={!validateForm()}>Submit</button>
      </form>
    </div>
  );
}
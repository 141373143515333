export default {
    s3: {
      REGION: "us-east-1",
      BUCKET: "medias-uploads"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://z5822ytc4i.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_TyB43Edor",
      APP_CLIENT_ID: "49p2eo2f2klm383ackcu44oq25",
      IDENTITY_POOL_ID: "us-east-1:4e657c62-0d2b-4970-8298-9bc397a3863b"
    }
  };

import React, { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";

import './App.css';
import Routes from "./Routes";

function App(props) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [ isAuthenticated, userHasAuthenticated ] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    
    userHasAuthenticated(false);

    props.history.push("/login");
  }

  return (
    !isAuthenticating &&
    <div className="mui-container">
      <div className="mui-appbar">
        <div className="mui-container-fluid">
          <div className="mui-row">
            <div className="mui-col-md-6"><Link to="/"><h1>ghis.io - CMaaS</h1></Link></div>
            <div className="mui-col-md-6">
              <ul className="header-menu">
                {isAuthenticated
                  ? <li><Link onClick={handleLogout}>Logout</Link></li>
                  : <>
                      <li><Link to="/login">Login</Link></li>
                    </>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mui-panel">
        <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
      </div>
    </div>
  );
}

export default withRouter(App);

import React from "react";
import "./Home.css";

export default function Home() {
  return (
    <div className="Home">
      <div className="lander">
        <h1>
          <span title="site name">ghis.io</span>
          <img src="/images/ghis_io_logo.png" />
        </h1>
        <p>A new way to manage your website. Content Management As A Service.</p>
      </div>
    </div>
  );
}
